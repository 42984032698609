<template>
    <AdminWrapper>
        <template v-slot:child>
            <div class=" pv4 w-90 center">
                <div>
                    <div class="pb3 pb0-l f5 b lh-copy">Create Partners</div>
                </div>
            </div>

            <form
                class="w-90 center flex-l items-start justify-between"
                @submit.prevent="onSubmit()"
                :class="{ valid: !v$.$error && v$.$dirty, error: v$.$error }"
            >
                <div class="box-border pa3 w-50-l w-100 mb4 mb0-l">
                    <div class="flex flex-column">
                        <div class="b pt4 pb2">Name</div>
                        <input type="text" v-model="state.name" class="pa2 box-border" placeholder="Enter role name" />
                        <div class="input-errors mt1" v-for="error of v$.name?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Description</div>
                        <textarea
                            type="text"
                            rows="5"
                            v-model="state.description"
                            class="pa2 box-border"
                            placeholder="description"
                        />
                        <div class="input-errors mt1" v-for="error of v$.description?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-column">
                        <div class="b pt3 pb2">Referral Link</div>
                        <input type="email" class="pa2 box-border" name="" placeholder="http;simplebks-uyieiro" id="" />
                    </div> -->
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Phone No</div>
                        <input
                            type="number"
                            v-model="state.phoneNumber"
                            class="pa2 box-border"
                            name=""
                            placeholder="07055896214"
                        />
                        <div class="input-errors mt1" v-for="error of v$.phoneNumber?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Partner Discount</div>
                        <input
                            type="number"
                            v-model="state.partnerDiscount"
                            class="pa2 box-border"
                            placeholder="Enter discount"
                        />
                        <!-- <select v-model="state.partnerDiscount" class="pa2 box-border">
                            <option value="">Select Discount</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="35">35</option>
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                        </select> -->
                        <div class="input-errors mt1" v-for="error of v$.description?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Customer Discount</div>
                        <input
                            type="number"
                            v-model="state.customerDiscount"
                            class="pa2 box-border"
                            placeholder="Enter discount"
                        />
                        <!-- <select v-model="state.customerDiscount" class="pa2 box-border">
                            <option value="">Select Discount</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="35">35</option>
                            <option value="40">40</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                        </select> -->
                        <div class="input-errors mt1" v-for="error of v$.description?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Duration (in month)</div>
                        <input
                            type="number"
                            v-model="state.duration"
                            class="pa2 box-border"
                            placeholder="Enter duration in month"
                        />
                        <!-- <select v-model="state.duration" class="pa2 box-border">
                            <option value="">Select Duration</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="20">12</option>
                        </select> -->
                        <div class="input-errors mt1" v-for="error of v$.description?.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-column pb4">
                        <div class="b pt3 pb2">Send as email invite</div>
                        <input type="text" class="pa2 box-border" name="" placeholder="staff@cojo.co" id="" />
                    </div> -->
                    <div class="mt2">
                        <div class="pb3">
                            <div class="b ">Custom Roles</div>
                            <div class="input-errors mt1" v-for="error of v$.roles?.$errors" :key="error.$uid">
                                <div class="error-msg">You have to pick at least one (1) permission access</div>
                            </div>
                        </div>
                        <div class="flex items-center pb3 box-border-bottom">
                            <input type="checkbox" value="1" v-model="state.roles" />
                            <div class="pl2 font-w1">All business records</div>
                        </div>
                        <div class="pt3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="2" />
                                <div class="pl2 font-w1">Transactions</div>
                            </div>
                            <div class="flex flex-wrap pl4 pt3">
                                <div class="flex items-center pr3  mb3">
                                    <input type="checkbox" v-model="state.roles" value="3" />
                                    <div class="pl1 font-w1">All</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="4" />
                                    <div class="pl1 font-w1">Sales</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="5" />
                                    <div class="pl1 font-w1">Expense</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="6" />
                                    <div class="pl1 font-w1">Purchase</div>
                                </div>
                                <div class="flex items-center mb3">
                                    <input type="checkbox" v-model="state.roles" value="7" />
                                    <div class="pl1 font-w1">Others</div>
                                </div>
                            </div>
                        </div>
                        <div class="pt3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="8" />
                                <div class="pl2 font-w1">Inventory</div>
                                <img src="imgs/dropdown.svg" class="pl2 pt1" width="11px" alt="" />
                            </div>
                            <div class="flex flex-wrap pl4 pt3">
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="9" />
                                    <div class="pl1 font-w1">Summary</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="10" />
                                    <div class="pl1 font-w1">All Product</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="11" />
                                    <div class="pl1 font-w1">Analysis</div>
                                </div>
                                <div class="flex items-center pr3  mb3">
                                    <input type="checkbox" v-model="state.roles" value="12" />
                                    <div class="pl1 font-w1">Product details</div>
                                </div>
                            </div>
                        </div>
                        <div class="pv3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="13" />
                                <div class="pl2 font-w1">Service</div>
                            </div>
                        </div>
                        <div class="pt3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="14" />
                                <div class="pl2 font-w1">Debt & Creditors</div>
                                <img src="imgs/dropdown.svg" class="pl2 pt1" width="11px" alt="" />
                            </div>
                            <div class="flex flex-wrap pl4 pt3">
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="15" />
                                    <div class="pl1 font-w1">Creditor</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="16" />
                                    <div class="pl1 font-w1">Debtor</div>
                                </div>
                            </div>
                        </div>
                        <div class="pt3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="17" />
                                <div class="pl2 font-w1">Entity</div>
                                <img src="imgs/dropdown.svg" class="pl2 pt1" width="11px" alt="" />
                            </div>
                            <div class="flex flex-wrap pl4 pt3">
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="18" />
                                    <div class="pl1 font-w1">Customer Details</div>
                                </div>
                            </div>
                        </div>
                        <div class="pv3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="19" />
                                <div class="pl2 font-w1">POS</div>
                            </div>
                        </div>
                        <div class="pv3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="20" />
                                <div class="pl2 font-w1">Production</div>
                            </div>
                        </div>
                        <div class="pt3 box-border-bottom">
                            <div class="flex items-center">
                                <input type="checkbox" v-model="state.roles" value="21" />
                                <div class="pl2 font-w1">Report</div>
                                <img src="imgs/dropdown.svg" class="pl2 pt1" width="11px" alt="" />
                            </div>
                            <div class="flex flex-wrap pl4 pt3">
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="22" />
                                    <div class="pl1 font-w1">Sales</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="23" />
                                    <div class="pl1 font-w1">Expense</div>
                                </div>
                                <div class="flex items-center pr3 mb3">
                                    <input type="checkbox" v-model="state.roles" value="24" />
                                    <div class="pl1 font-w1">Inventory</div>
                                </div>
                                <div class="flex items-center pr3  mb3">
                                    <input type="checkbox" v-model="state.roles" value="25" />
                                    <div class="pl1 font-w1">Profit & Loss</div>
                                </div>
                                <div class="flex items-center pr3  mb3">
                                    <input type="checkbox" v-model="state.roles" value="26" />
                                    <div class="pl1 font-w1">Balance sheet</div>
                                </div>
                                <div class="flex items-center pr3  mb3">
                                    <input type="checkbox" v-model="state.roles" value="27" />
                                    <div class="pl1 font-w1">Invoice</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tr pt3">
                        <!-- <button class="btn3">Cancel</button> -->
                        <button class="btn1 ml2" :disabled="$store.state.Admin.loadingState">
                            {{ $store.state.Admin.loadingState ? 'Processing' : 'Create partner' }}
                        </button>
                    </div>
                </div>

                <div class="box-border pa3 w-50-l w-100 ml4-l ">
                    <div class="box-border-bottom">
                        <h3>Profile photo</h3>
                    </div>

                    <div class="flex pv4">
                        <img :src="require('@/assets/images/profile-pic.svg')" alt="" />
                        <div class="pl3">
                            <div class="font-w2 pb2">Edit your photo</div>
                            <div class="flex">
                                <a href="">Delete</a>
                                <a href="" class="ml3 font-w2" style="color:#132C8C;">Update</a>
                            </div>
                        </div>
                    </div>
                    <label for="file-upload" class="custom-file-upload w-100 bw1 dashedinputFile" style="text-align:center">
                        <img :src="require('@/assets/images/images.svg')" class="pb3 pt4" alt="" />
                        <div class="font-w2 pb2">
                            Drag and drop an image, or <span href="" style="color:#132C8C;">Browse</span>
                        </div>
                        <div class="font-w1 pb3">386 * 250 PX or higher recommended, Max 3MB (webp, png, jpg)</div>
                        <input id="file-upload" type="file" accept=".webp, .png, .jpg, .jpeg" style="visibility: hidden" />
                    </label>
                </div>
            </form>
        </template>
    </AdminWrapper>
</template>

<script>
import { reactive } from '@vue/reactivity'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import useVuelidate from '@vuelidate/core'
import { required, alpha, numeric, minLength } from '@vuelidate/validators'
import { useStore } from 'vuex'
export default {
    components: {
        AdminWrapper
    },
    setup() {
        const store = useStore()
        const state = reactive({
            name: '',
            description: '',
            phoneNumber: '',
            customerDiscount: '',
            partnerDiscount: '',
            duration: '',
            roles: []
        })

        const rules = {
            name: { required },
            description: { required, minLengthValue: minLength(10) },
            phoneNumber: { required, numeric },
            customerDiscount: { required, numeric },
            partnerDiscount: { required, numeric },
            duration: { required, numeric },
            roles: { required }
        }

        const onSubmit = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            store.dispatch('Admin/createPartner', {
                name: state.name,
                description: state.description,
                phoneNumber: state.phoneNumber,
                customerDiscount: state.customerDiscount,
                partnerDiscount: state.partnerDiscount,
                duration: state.duration,
                roles: state.roles
            })
            // console.log('validation', v$.value)
        }

        const v$ = useVuelidate(rules, state)

        return { state, v$, onSubmit }
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}
</style>
